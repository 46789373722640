import React, { useState, useEffect } from "react";
import Router, { useRouter } from "next/router";
import useStore from "./../store";
import { removeCookie, updateCookie, useCookies, getCookie } from "../utils/helpers"
import { useCustomTranslation } from '@utils/translations';
import { getAllCurrencies } from "../store/storeActions";
import { setgid } from "process";
import { urlGeoAPI } from "@components/utils/server-links";
import ModalCountries from "@components/Modal/ModalCountries";

let checkSession: any = null;
declare global {
  interface Window {
    webFunnel?: any;
    turnstile?: any;
    clarity?: any;
    customerId?: any;
    clarityNext?: any;
    zustandStore?: any;
  }
}

const withLoadedData = (Component: JSX.Element, auth: boolean = false) => {
  
    const AuthenticatedComponent = () => {
        const router = useRouter();
        const countries = useStore((state) => state.countries);
        if (typeof window != 'undefined') {
          window.zustandStore = useStore.getState();
        }
        
        const { t } = useCustomTranslation();
        const allLicenses = useStore((state) => state.allLicenses);
        const allCurrencies = useStore((state) => state.allCurrencies);
        const defaultCountry = useStore(state => state.defaultCountry);
        const geoCountry = useStore(state => state.geoCountry);
        const [showResidenceModal, setShowResidenceModal] = useState(false);
        const globalConfigurations = useStore((state) => state.globalConfigurations);
        const user = useStore((state) => state.user)
        const setUser = useStore((state) => state.setUser)
        const setGeoCountry = useStore((state) => state.setGeoCountry)
        const storeToken = useStore((state) => state.token)
        const locales = useStore((state) => state.locales)

        const setCountriesList = useStore((state) => state.setCountriesList);
        const setAllLicenses = useStore((state) => state.setAllLicenses);
        const getGlobalConfigurations = useStore((state) => state.getGlobalConfigurations);
        const getToken = useStore((state) => state.getToken);
        const setToken = useStore((state) => state.setToken);
        const setAllCurrencies = useStore((state) => state.setAllCurrencies);
        const setLocalesList = useStore((state) => state.setLocalesList);
        const defaultCountryOptions = useStore((state) => state.defaultCountryOptions);
        const platformDefault = useStore((state) => state.setPlatformDefault);
        const getCookieHook = useCookies()
        const removeToken: any = ['/login', '/register', '/user/forgot'];


        useEffect(() => {
          if (typeof window != 'undefined') {
            (function(window) {
              const SET_INITIAL_DATA = 'set_initial_data'!;
              const context = top!;
              class WebFunnel {
                  funnelState: {};
                  isIframe: null;
                  version: string;

          
                  constructor() {
                      this.funnelState = {};
                      this.isIframe = null;
                      this.version = '1.0.0';
                  }
          
                  /**
               * After rendering the HTML structure, method window.webFunnel.onInit()
                   * must be called to set up the data that was not included in the queryString.
                   */
                  onInit() {
                      //console.log('[WebFunnel] onInit()');
                      if (this.isIframe) {
                          const body = document.querySelector('body')  as HTMLBodyElement;
                          body.classList.add('web-funnel-iframe');
                          body.classList.add('web-funnel-iframe-style');
                          context.postMessage({
                              event: "on_load"
                          }, '*');
                      }
                  }
          
                  close() {
                      if (this.isIframe) {
                          // web close iframe
                          context.postMessage({
                              event: 'triggered_close',
                          }, '*');
                        }
                        // change the hash with nextjs router to #{"event":"deposit_close","type":"DEPOSIT","action":"close"}, it is done for mobile
                      const closeEvent = {"event":"deposit_close","type":"DEPOSIT","action":"close"}
                      window.webFunnel.callExternalHooks(closeEvent);
                      window.location.hash = JSON.stringify(closeEvent);
                  }
                  switchAndCloseAccount(data: any) {
                      const eventObject = JSON.stringify({
                        "type" : "triggered_close", 
                        event: 'triggered_close',
                        // ...(data?.globalConfigurations?.enable_deactivation_of_accounts && {
                        //   ...(data?.tradingAccountType && data?.deactivate_accounts_list?.length > 0 && {"switch_trading_account_type": data?.tradingAccountType === 'REAL'? 'live':'demo'}),
                        //   ...(data?.platformAccountId && data?.deactivate_accounts_list?.length > 0 && {"switch_to_account": data?.platformAccountId}),
                        //   ...(data?.tradingAccountInstrumentType && data?.deactivate_accounts_list?.length > 0 && {"switch_instrument_type": data?.tradingAccountInstrumentType}),
                        //   ...(data?.deactivate_accounts_list && data?.deactivate_accounts_list?.length > 0 && {"deactivate_accounts_list": data?.deactivate_accounts_list}),
                        // }),
                        action: 'close',
                      })
                      if (this.isIframe) {
                          // web close iframe
                          context.postMessage(`${eventObject}`, '*')
                      }
                        // change the hash with nextjs router to #{"event":"deposit_close","type":"DEPOSIT","action":"close"}
                      const closeEvent = eventObject;
                      window.webFunnel.callExternalHooks(closeEvent);
                      window.location.hash = closeEvent;
                  }
                  /**
                   * Communication interface with the parent
                   *
                   * @returns {Event} for core.trigger or other
                   */
                  callExternalHooks(event: any) {
                      //console.log('[WebFunnel] callExternalHooks()');
                      if (this.isIframe) {
                          return context.postMessage(event, '*');
                        } else {
                          return context?.postMessage(event, '*');
                      }
                  };
                  completeRegistration() {
                      //console.log('[WebFunnel] close()');
                      if (this.isIframe) {
                          context.postMessage({
                              event: 'completeRegistration',
                          }, '*');
                      }
                  }
          
                  getValue(param: any) {
                      return this.findPropertyValue(this.funnelState, param);
                  }
                  ;
                  findPropertyValue(object:any, property:any) {
                      const parseProperty = property.split('.');
          
                      if (!parseProperty)
                          return;
          
                      if (parseProperty.length === 1) {
                          return object[parseProperty];
                      }
          
                      return object[parseProperty[0]][parseProperty[1]];
                  }
                  ;
              }
          
                if (typeof window?.webFunnel === 'undefined') {
                    //console.log("WebFunnel initialized");
                    window.webFunnel = new WebFunnel();
                    window.webFunnel.isIframe = window?.parent?.location && window.location !== window?.parent?.location;
                    window.webFunnel.onInit();
                } else {//console.log("WebFunnel library already defined.");
              }
          }
          )(window);
          }
        }, [user]);
        // useEffect(() => {
        //   if (defaultCountryOptions.locale) {
        //     const locale = defaultCountryOptions.locale;
        //     i18n.changeLanguage(locale.code);
        //   } else {
        //     i18n.changeLanguage('en');
        //   }
          
        // }, [defaultCountryOptions, i18n, defaultCountry]);

      
        useEffect(() => {
          if (router.isReady){
          if (typeof window != 'undefined' && window && window.clarity) {
            window.clarity('upgrade', 'force-session-record-for-customers')
          }

          if(!globalConfigurations){
            getGlobalConfigurations();
          }
          
          if(!user && auth) {
            setUser()
          }
      
          if (!checkSession) {
            checkSession = setInterval(() => {
              const token = getCookieHook("token")
              if (auth && !token) {
                removeCookie('rm');
                if (window?.location?.href?.includes('/quiz-without-flow')) {
                  window.location.href = '/user/login-into-flow';
                } else {
                  Router.push("/login");
                }
                location.reload();
              }
            }, 5000);
          }
          }
          return () => {
            if (checkSession) {
              clearInterval(checkSession);
              checkSession = null;
            }
          }
        }, [router.isReady]);

        useEffect(() => {
          const setCustomerDetails = async () => {
            let countryIso = geoCountry || "";
            try {
              const data = await fetch(`${process.env.NEXT_PUBLIC_BE_HOST}${urlGeoAPI}`, {
                "method": "GET",
                // it will set the cookie in response, allow them to be cross domain
                "credentials": "include"
              });
              const json = await data.json();
              if (json.country==='xx') {
                countryIso = 'sc';
                setGeoCountry('sc');
              } else {
                countryIso = json.country;
                setGeoCountry(countryIso);
              }
            } catch (error) {
              countryIso = 'sc';
              setGeoCountry('sc');
              console.error('Error GEO API:', error);
            }
            if (!countries){
              setCountriesList();
            }
            if (countryIso === "" || countryIso === "xx") {
              return;
            }

            if(auth && !user){
              return;
            }
            
            if(removeToken.indexOf(router.pathname) > -1) {
              removeCookie('token');
              removeCookie('rm');
            }
  
            const rm = getCookie('rm');
            if (auth && !rm) {
              updateCookie('token');
            }
            const token = getCookieHook('token')
            
            if (auth && (!token || token == 'null')) {
              removeCookie('rm');
              if (router?.asPath?.includes('/quiz-without-flow')) {
                window.location.href = '/user/login-into-flow';
              } else {
                Router.push("/login");
              }
              return;
            }
            
            if (!defaultCountry && !allLicenses) {
              let removeCountryParam: boolean = false;
              let countryFromParam: any = new URLSearchParams(window?.location?.search || '?').get('country');

              if (countryFromParam) {
                if (countryFromParam.length > 2) {
                  removeCountryParam = true;
                  // @ts-ignore
                } else if (!countriesResponse?.find((country: any) => country.code === countryFromParam)) {
                  removeCountryParam = true;
                }
              }

              if (removeCountryParam) {
                let paramsWithoutCountry = router.query;
                delete paramsWithoutCountry.country;
                router.push({
                  pathname: '/register',
                  query: paramsWithoutCountry
                });
              } else {
                countryIso = new URLSearchParams(window?.location?.search || '?').get('country') || user?.country || countryIso || getCookie('cfCountryCookie');
              }
              if (!locales) {
                setLocalesList();
              }
              setAllLicenses(countryIso);
              setGeoCountry(countryIso);
              platformDefault(countryIso);
            }

            if (!allCurrencies) {
              setAllCurrencies();
            }
          }
          if (router.isReady){
            setCustomerDetails();
          }
        }, [geoCountry,router.isReady, user, auth, router.query]);

        useEffect(() => {
          if (locales && locales?.length > 0) {
            if (user && user?.locale) {
              if (router.locale === user?.locale) {
                return;
              } else if (locales.some(locale => locale.code === user?.locale)) {
                router.push({pathname: router.pathname, query: router.query}, undefined, {locale: user?.locale})
              }
            }
          }
        }, [user, user?.locale, locales]);
        if (showResidenceModal && !!countries) {
          return (
            <ModalCountries 
              title={t('register.undetectedCountry.title')}
              show={showResidenceModal}
              onCallback={(data: { code: string; }) => {
                setShowResidenceModal(false);
                setGeoCountry(data?.code);
                console.log('setGeoCountry', data?.code)
              }} 
              data={{
                name: "",
                code: "xx",
                phonePrefix: ""
              }}
              />
            );
        }
        // @ts-ignore
        return (!!countries && !!defaultCountry && (!auth || (auth && !!user))) ? (<Component />) : (
            <div className="loadingcenter">
                <div className="loader"></div>
            </div>
        );
    };

  return AuthenticatedComponent;
};

export default withLoadedData;
